import {Base} from './Base'

export function Company (clientId, settings) {

    let base = null

    function run (settings) {
        if (settings.company) {

            base = Base(
                settings,
                clientId,
                settings.company,
                settings.country,
                'name_company',
                function () {
                    return '/v1/company'
                },
                function (phrase) {
                    let countryValue = ''

                    if (settings.country && settings.country.nodeType && settings.country.tagName.toLowerCase() === 'select') {
                        countryValue = (settings.country ? settings.country.value : '')
                    } else if (typeof settings.country === 'string') {
                        countryValue = settings.country
                    }

                    return {
                        'query': {
                            'company': phrase,
                            'country': countryValue
                        }
                    }
                },
                function (data) {
                    if (data) {
                        if (settings.company) {
                            settings.company.value = data.values.name_company
                        }

                        if (settings.ico) {
                            settings.ico.value = data.values.ico
                        }

                        settings.dic.value = ''
                        if (settings.dic && data.values.dic !== '') {
                            settings.dic.value = 'CZ' + data.values.dic
                        }
                    }
                },
                function () {
                    settings.company.value = ''

                    if (settings.ico) {
                        settings.ico.value = ''
                    }

                    if (settings.dic) {
                        settings.dic.value = ''
                    }
                },
                function (dataItem) {
                    return '<div class="item-company">' +
                        '<div class="name-wrapper">' +
                        '<div class="name-company">' + dataItem.values.name_company + '</div>' +
                        '<div class="address">' + dataItem.values.address + '</div>' +
                        '</div>' +
                        '<div class="ico-wrapper">' +
                        '<div class="ico">IČO ' + dataItem.values.ico + '</div>' +
                        '</div>' +
                        '</div>'
                },
                function () {
                    return 'item-company-wrap'
                }
            )
        }
    }

    function setSettings (newSettings) {
        if(base) {
            base.removeListeners()
            run({...settings, ...newSettings})
        }
    }

    run(settings)

    return {
        setSettings
    }
}


