import {Base} from './Base'

export function City (clientId, settings) {

    let base = null

    function run (settings) {

        if (settings.city) {
            base = Base(
                settings,
                clientId,
                settings.city,
                settings.country,
                'city',
                function () {
                    return '/v1/city'
                },
                function (phrase) {
                    let countryValue = ''

                    if (settings.country && settings.country.nodeType && settings.country.tagName.toLowerCase() === 'select') {
                        countryValue = (settings.country ? settings.country.value : '')
                    } else if (typeof settings.country === 'string') {
                        countryValue = settings.country
                    }

                    return {
                        'query': {
                            'city': phrase,
                            'country': countryValue
                        }
                    }
                },
                function (data) {
                    if (data) {
                        settings.city.value = data.values.city
                    }
                },
                function () {
                    settings.city.value = ''
                }
            )
        }
    }

    function setSettings (newSettings) {
        if(base) {
            base.removeListeners()
            run({...settings, ...newSettings})
        }
    }

    run(settings)

    return {
        setSettings
    }
}


