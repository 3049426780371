import {BodyFormParser} from "./BodyFormParser";

const FetchJson = async function (url, data) {
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(data),
    });

    return await res.json();
}

const PostForm = async function (url, data) {
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: BodyFormParser(data),
    });

    return await res.json();
}

export {FetchJson, PostForm}
