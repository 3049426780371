import {Place} from './Place'
import {City} from './City'
import {Company} from './Company'
// import {PostForm} from "../Utils/FetchData";

export function init (clientId, initSettings) {

    // let domain = ''
    // if(typeof acIsLocalMode === 'undefined' || !acIsLocalMode) {
    //     domain = 'https://api.masterform.cz'
    // }
    // const url = domain + '/v1/init'
    // PostForm(url, {host: window.location.hostname}).then(function (data) {
    //     if(data) {
    //         window.masterFormToken = data.token
    //     }
    // })

    function place(settings) {
        return Place(clientId, {...initSettings, ...settings})
    }

    function city(settings) {
        return City(clientId, {...initSettings, ...settings})
    }

    function company(settings) {
        return Company(clientId, {...initSettings, ...settings})
    }

    return {
        place,
        city,
        company
    }

}


