import {FetchJson, PostForm} from '../Utils/FetchData'
import { v4 as uuidv4 } from 'uuid';


export function Base (settings, clientId, input, country, dataItemKey, onSetUri, onSetData, onCompletedSelectedItem, onClearForm, onValueItem, onCustomClassItem) {
    let acWindow = null
    let nameId = 'a' + uuidv4()

    createAutocompleteWindow()

    const listeners= ['keyup', 'paste', 'click']
    listeners.forEach( function(evt) {
        input.addEventListener(evt, inputEvent)
    })

    document.addEventListener('click', clickDocumentEvent)
    window.addEventListener('resize', resizeWindowEvent,true)
    input.addEventListener('keydown', moveOnAutocompleteEvent)

    if(country) {
        if(country && country.nodeType && country.tagName.toLowerCase() === 'select') {
            country.addEventListener('change', function (e) {
                e.preventDefault()
                onClearForm()
            })
        }
    }

    function inputEvent (e) {

        if(e.which === 27) {
            closeAcWindow()
        } else {

            if(e.which === 13 || e.which === 40 || e.which === 38) {
                return
            }

            const value = this.value

            if(value) {
                searchData(value)
            } else if(e.which !== 1) {
                onClearForm()
                closeAcWindow()
            }
        }
    }

    function clickDocumentEvent(e) {
        const element = e.target

        if (element.closest('.masterform-autocomplete') === null && element !== input) {
            closeAcWindow()
        }

        const item = element.closest('.item')
        if(element.closest('#' + nameId) && item) {
            searchData(item.getAttribute('data-value'), item)
        }
    }

    function resizeWindowEvent(e) {
        setPositionAcWindow()
    }

    function moveOnAutocompleteEvent(e) {

        if (e.which === 13) {
            e.preventDefault()

            let activeItem = acWindow.querySelector('.active')

            if (activeItem !== null) {
                searchData(activeItem.getAttribute('data-value'), activeItem)
            }

        } else if (e.which === 40) {

            let activeItem = acWindow.querySelector('.active')

            if (activeItem !== null) {
                activeItem.classList.remove('active');

                let nextItem = activeItem.nextSibling

                if (nextItem !== null) {
                    nextItem.classList.add('active')
                }

            } else {
                let firstItem = acWindow.querySelector('.item:first-of-type')

                if (firstItem !== null) {
                    firstItem.classList.add('active')
                }
            }

        } else if (e.which === 38) {
            let activeItem = acWindow.querySelector('.active')

            if (activeItem !== null) {
                activeItem.classList.remove('active')

                let prevItem = activeItem.previousSibling

                if (prevItem !== null) {
                    prevItem.classList.add('active')
                }
            }
        }
    }

    function searchData(phrase, selectedItem = null) {
        let domain = ''
        if(typeof acIsLocalMode === 'undefined' || !acIsLocalMode) {
            domain = 'https://api.masterform.eu'
        }

        setPositionAcWindow()

        const url = domain + onSetUri()
        let data = onSetData(phrase)

        data.clientId = clientId
        FetchJson(url, data).then(function (data) {
            if(data) {
                displayContent(data, phrase, selectedItem)
            }
        })
    }

    function displayContent(data, phrase, selectedElement) {
        let content = ''

        let id = ''
        if(selectedElement) {
            id = selectedElement.getAttribute('data-id')
        }

        if((id === '' || id === '0') && data.items.length > 0) {
            content = getList(data.items)
        }

        if(selectedElement && data.items.length > 0) {
            let selectedDataItem = null
            data.items.forEach(function(item) {
                if(item.values.id && item.values.id === id) {
                    selectedDataItem = item
                }
            })

            if(selectedDataItem) {

                onCompletedSelectedItem(selectedDataItem)

                if(settings.onCompletedSelectedItem) {
                    settings.onCompletedSelectedItem(selectedDataItem)
                }

            } else {
                input.value = phrase
            }
        }

        if(content) {
            acWindow.innerHTML = '<div class="masterform-autocomplete">'+content+'</div>'
        } else {
            closeAcWindow()
        }
    }

    function getList(data) {
        let list = ''

        data.forEach(function(item) {
            let countryCodeClass = '';
            if(item.values.countryCode) {
                countryCodeClass = item.values.countryCode
            }
            list += '<div class="item ' + countryCodeClass + ' '+getCustomClassItem(item)+'" data-id="' + (item.values.id ? item.values.id : 0) + '" data-value="'+item.suggestionValue+'">' + getValueItem(item) + '</div>'
        })

        return list
    }

    function getCustomClassItem(dataItem) {
        let customClass = ''

        if (onCustomClassItem) {
            customClass = onCustomClassItem(dataItem)
        }

        return customClass
    }

    function getValueItem(dataItem) {
        let value = ''

        if(settings.onValueItem) {
            value = settings.onValueItem(dataItem)
            value = value === undefined ? '' : value
        } else {
            if (onValueItem) {
                value = onValueItem(dataItem)
            } else {
                value = dataItem.suggestionValue
            }
        }

        return value
    }

    function createAutocompleteWindow() {
        let div= document.createElement('div')
        div.id = nameId
        div.style.position = 'absolute'
        div.classList.add('masterform-v1')

        if(settings.theme) {
            div.classList.add(settings.theme)
        } else {
            div.classList.add('default')
        }

        document.body.appendChild(div)
        acWindow = document.getElementById(nameId)
    }

    function setPositionAcWindow() {
        const elementRect = input.getBoundingClientRect()
        const top = elementRect.top + elementRect.height
        const left = elementRect.left

        acWindow.style.top = top + window.scrollY + 'px'
        acWindow.style.left = left + window.scrollX + 'px'
        acWindow.style.width = elementRect.width + 'px'
    }

    function closeAcWindow() {
        if(acWindow !== null) {
            acWindow.innerHTML = ''
        }
    }

    function removeListeners() {
        input.removeEventListener('keyup', inputEvent);
        input.removeEventListener('paste', inputEvent);
        input.removeEventListener('keydown', moveOnAutocompleteEvent)
        document.removeEventListener('click', clickDocumentEvent)
        window.removeEventListener('resize', resizeWindowEvent,true)
    }

    return {
        removeListeners
    }
}




