import {Base} from './Base'

export function Place (clientId, settings) {

    let base = null

    function run (settings) {

        if(settings.place) {

            base = Base(
                settings,
                clientId,
                settings.place,
                settings.countryCode,
                'address',
                function () {
                    return '/v1/place'
                },
                function(phrase) {
                    let countryCodeValue = ''

                    if(settings.countryCode && settings.countryCode.nodeType && settings.countryCode.tagName.toLowerCase() === 'select') {
                        countryCodeValue = (settings.countryCode ? settings.countryCode.value : '')
                    } else if (typeof settings.countryCode === 'string') {
                        countryCodeValue = settings.countryCode
                    }

                    return {
                        'query': {
                            'place': phrase,
                            'city': (settings.city ? settings.city.value : ''),
                            'postCode': (settings.postCode ? settings.postCode.value : ''),
                            'countryCode': countryCodeValue
                        },
                    }
                },
                function(data) {
                    if(data) {
                        settings.place.value = data.values.placeAndNumber

                        if (settings.city) {
                            settings.city.value = data.values.city
                        }

                        if (settings.postCode) {
                            settings.postCode.value = data.values.postCode
                        }
                    }
                },
                function () {
                    settings.place.value = ''

                    if (settings.city) {
                        settings.city.value = ''
                    }

                    if (settings.postCode) {
                        settings.postCode.value = ''
                    }
                }
            )
        }
    }

    function setSettings (newSettings) {
        if(base) {
            base.removeListeners()
            run({...settings, ...newSettings})
        }
    }

    run(settings)

    return {
        setSettings
    }

}


